import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { FigmaDemoService } from '@app/figma-demo/figma-demo.service';

import { IFigmaIcon } from './../interfaces/generic.interface';

/**
* @deprecated Use quest-icon instead
*/
@Component({
	selector: 'app-figma-icon-img',
	templateUrl: './figma-icon-img.component.html',
	styleUrls: ['./figma-icon-img.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FigmaIconImgComponent implements OnInit {
	@Input() figmaId?: string;
	@Input() iconName: string;
	@Input() isForButton = false;
	@Input() height?: number;
	@Input() width?: number;
	@Input() hasSpaceOnRight = true;
	@Input() hasSpaceOnLeft = false;

	constructor(
		private _sanitizer: DomSanitizer,
		private _figmaService: FigmaDemoService,
    private _cd: ChangeDetectorRef
	) {}

	get src(): SafeResourceUrl {
		return this.figmaId
			? this.getSrcByIdafeResourceUrl()
			: this.getSrcByNameSafeResourceUrl();
	}

	ngOnInit() {}

	getSrcByIdafeResourceUrl(): SafeResourceUrl {
		let svgSrc = this._sanitizer.bypassSecurityTrustResourceUrl('');

		if (this.figmaId) {
			const src = this._figmaService.getSrc(this.figmaId) ?? '';
			svgSrc = this._sanitizer.bypassSecurityTrustResourceUrl(src);
		}

		return svgSrc;
	}

	getSrcByNameSafeResourceUrl(): SafeResourceUrl {
    this._cd.markForCheck();
		const figma: IFigmaIcon[] = JSON.parse(
			localStorage.getItem('newFigma') || ''
		);
		return this.iconName === undefined ||
			this.iconName === null ||
			this.iconName === 'null' ||
			this.iconName?.includes('fa-')
			? ''
			: figma.filter((e) => e.name === this.iconName)[0].iconUrl;
	}
}
